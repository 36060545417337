@font-face {
  font-family: 'GT Pressura';
  src: url(./fonts/GT-Pressura-Regular.woff2) format('woff2');
}

html {
	font-size: 10px;
    height: 100%;
}

body{
    height: 100%;
}

.App {
    font-family: sans-serif;
    text-align: center;
  }

.mainLogo {
	width:155px;
	height: auto;
	margin:0 0 30px;
}

@media (min-width: 576px) {
    .mainLogo {
        margin:0 0 50px;
    }
}

.container {
	font-size: 1.6rem;
}

h1 {
	font-family: "GT Pressura", sans-serif;
}

p {
	font-family: "Neue Helvetica", "Helvetica",Arial,sans-serif;
}

h1.app {
	font-size: 36px;
    line-height: 44px;
    letter-spacing: -.5px;
    margin-bottom: 24px;
}

p.app {
	font-size: 20px;
    line-height: 28px;
    margin:0;
}


input.smscode {
	position: relative;
    display: block;
    width: 100%;
    color: #1a1a1a;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #868e96;
    border-radius: .1rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 1.1rem .8rem 1.1rem 1.6rem;
    vertical-align: text-top;
    margin-top:2.4rem;
}

input.smscode:focus,
input.smscode:active,
.react-tel-input .form-control:focus,
.react-tel-input .form-control:active {
	outline:0;
	box-shadow: 0 0 0 !important;
    outline: 0;
}


.react-tel-input,
.smscode {
	 margin-top:2.4rem !important;

}
.react-tel-input .form-control,
.smscode {
    font-size: 16px !important;
    height: 50px !important;
    border-radius: .2rem !important;
    width: 100% !important;
    font-weight: 400 !important;
 
}

.btn-primary {
	background:#00c878;
	font-size: 1.6rem;
	padding:1.1rem 3.5rem;
	border: 1px solid #00c878;
	line-height: 2.4rem;
	border-radius: .2rem;
}

.btn-primary.empty {
	background:transparent;
	color:#00c878;
}

.btn-primary.empty:hover,
.btn-primary.empty:focus {
	color:#fff;
}

.btn-primary:hover {
	background:#24b17d!important;
	border: 1px solid #24b17d;
}

.btn-primary:focus {
	background:#00c878 !important;
	border: 1px solid #00c878 !important;
	outline:4px solid rgba(0, 200, 120,.3) !important;
	box-shadow: 0 0 0 !important;
}


.btn-primary.register {
	margin: 36px 10px 0 0;
}

.notice {
	margin:15px 0 0;
}

.notice a{
	color:#24b17d;
}

.timerHidden {
	display: none;
}

button:disabled {
    border-color: #ccc !important;
    color: #666 !important;
    background: #eee !important;
}

p.wrongPhone,
p.wrongCode {
    background: #f03d3f;
    padding: 1.1rem 4rem;
    text-align: center;
    color: #fff;
    margin: 10px 0 0;
    border-radius: .2rem;
}

p.wrongPhone a,
p.wrongCode a {
    color: #fff;
}

p.wrongPhone a:hover,
p.wrongCode a:hover {
    color: #eee;
}

.verticalCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.captcha {
    /* visibility: hidden; */
}